/* React error overlay glitches and leaves the iframe visible with max z-index
*/
iframe {
  display: none !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: none !important;
}
